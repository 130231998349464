@import "~bootstrap/scss/bootstrap";

.wrapper {
	display: flex;
	align-items: center;
	width: 100%;
	height: 100vh;
	color: #fff;
}
.content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.title {
	font-size: 3.5rem;
	font-weight: 300;
	@include media-breakpoint-up(md) {
		font-size: 6rem;
		font-weight: 300;
	}
	text-align: center;
}
